import React from "react";
import HeaderCSS from '../Header/Header.css'
import Porco from '../../assets/img/Header-Icons/Porco.png'
import Verificado from '../../assets/img/Header-Icons/Verificado.png'
import Balance from '../../assets/img/Header-Icons/Balance.png'
import Acute from '../../assets/img/Header-Icons/Acute.png'

function CardsHeader () {
    return (
        <div className="container" data-aos="zoom-in" data-aos-duration="2000" style={{ position: 'relative', zIndex: 2 }}>
                        <div className="row mt-5 d-flex align-items-center row-icons">
                            <div className="col-sm-3">
                                <div className="card card1-nav align-items-center text-center w-100">
                                    <div className="card-body">
                                        <div className="card-title title1-card-nav">

                                            <img className="img1-nav mt-4 img-fluid" src={Porco} alt="" />

                                            <h6 className="fw-normal fw-bold mt-3 text-white title-card1">Planejamento financeiro</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="card card2-nav text-center align-items-center w-100">
                                    <div className="card-body">
                                        <div className="card-title title2-card-nav ">

                                            <img className="img2-nav mt-4 img-fluid" src={Verificado} alt="" />

                                            <h6 className="fw-normal fw-bold mt-3 text-center text-white title-card2">Planejamento financeiro</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="card card3-nav text-center align-items-center w-100">
                                    <div className="card-body">
                                        <div className="card-title title3-card-nav">

                                            <img className="img3-nav mt-4" src={Balance} alt="" />

                                            <h6 className="fw-normal mt-3 fw-bold text-center text-white title-card3">Planejamento financeiro</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="card card4-nav text-center align-items-center w-100">
                                    <div className="card-body">
                                        <div className="card-title title4-card-nav">

                                            <img className="img4-nav mt-4" src={Acute} alt="" />

                                            <h6 className="fw-normal mt-3 fw-bold text-center text-white title-card4">Planejamento financeiro</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
    )
}

export default CardsHeader;