import React from "react";
import ThiagoCSS from './Thiago.css';
import Thiagoimg from '../../assets/img/Thiago/Thiago.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

function Thiago() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);


    return (
        <section>
            <div className="container-fluid">
                <div className="row d-flex align-items-center main-thiago" style={{backgroundImage: `url(${Thiagoimg})`, backgroundSize: 'cover', backgroundPosition: 'center 20%', backgroundRepeat: 'no-repeat', position: 'relative'}}>

                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Preto com 50% de opacidade
                            zIndex: 1
                        }}
                    ></div>




                    <div className="col-sm-4 text-center" data-aos="fade-right" data-aos-duration="2000" style={{ position: 'relative', zIndex: 2 }}>
                        <p className="display-6 fw-bold title-thiago">
                            +MODERNO<br />
                            +SEGURO<br />
                            +INOVADOR
                        </p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Thiago;