import React from "react";
import DiferenciaisCSS from './Diferenciais.css';
import CardsDiferenciais from '../Cards-Diferenciais/Cards-Diferenciais.jsx'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

function Diferenciais() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({duration: 2800});
      }, []);
      

    return( 
        <section>
            <div className="container-fluid">
                <div className="row align-items-center main-dif">

                    <div className="col-sm-10 offset-sm-1">

                       <div className="d-flex display-6 fw-bold flex-column" data-aos="fade-right">
                         <p className="title-dif">Temos a solução ideal para você</p>  
                       </div> 

                    </div>

                </div>


            <CardsDiferenciais/>   
            
            </div>
        </section>
    )
}

export default Diferenciais;