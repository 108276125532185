import React from "react";
import FooterCSS from './Footer.css'
import Logo2 from '../../assets/img/Logo/Logo2.png'
import Line from '../../assets/img/Line/Line 1.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import Instagram from '../../assets/img/Social-Items/Instagram.png'

function Footer() {

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

    return (
        <section>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center row-footer">
                    <div className="col-sm-4 d-flex flex-column justify-content-center align-items-center">

                        <div className="container-img">
                            <img className="img-fluid line" data-aos="fade-right" src={Line} alt="" />

                            <img className="w-20 logo2" data-aos="zoom-in" src={Logo2} alt="" />
                        </div>



                        <div className="container-title" data-aos="zoom-in">
                            <h6 className="text-white fw-bold fs-4 title1-footer">Especializados em empresa</h6>
                                
                            <p className="text-white mt-4 subtitle1-footer">Recuperando empresas e crescendo</p>
                        </div>


                        <div className="container-input" data-aos="zoom-in">
                            <p className="text-white phone-number">Telefone: (83) 9 XXXX-XXXX</p>

                            <input type="text" className="form-control shadow-sm placeholder-footer" placeholder="Fale com a gente" />

                            <p className="copy-right text-white">© 2024 Todos os Direitos Reservados | CNPJ: xx.xxx.xxxx/xxxx-xx</p>
                        </div>

                    </div>



                    <div className="col-sm-4 d-flex flex-column justify-content-center align-items-center" data-aos="zoom-in">
                        <div className="container2">
                            <h6 className="text-white fs-4 fw-bold title2-footer">Venha nos ver</h6>

                            <p className="rua text-white">R. Josita Almeida, 240, Escritório 06 Altiplano</p>

                            <p className="cidade text-white">João Pessoa - PB</p>

                            <p className="cep text-white">CEP: 58046-490</p>
                        </div>
                    </div>

                    <div className="col-sm-4 d-flex flex-column justify-content-center align-items-center" data-aos="zoom-in">
                        <div className="container-icons">    
                            <h6 className="text-white fw-bold fs-5 title3-footer">Encontre-nos nas redes sociais</h6>

                            <img className="instagram" src={Instagram} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;