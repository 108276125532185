import React from 'react'
import BrasilCSS from './Brasil.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Brasil2 from '../../assets/img/Brasil/Brasil2.png'

function Brasil() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])
    
    return (
        <section>

            <div className='container'>
                <div className="row row-brasil align-items-center">

                    <div className='col-sm-6 justify-content-center' data-aos="fade-right">
                        <img className='img-fluid img-brasil' src={Brasil2} alt="" />
                    </div>

                    <div className='col-sm-6 gap-5 h-50 justify-content-center text-end brasil-text' data-aos="fade-left">

                    <h5 className="display-4 d-flex flex-column fw-bold title-brasil">Contabilidade para empresas em todo o Brasil.</h5>

                    <p className="d-flex flex-column display-5 subtitle-brasil">Atendendo empresas em todo o territorio nacional com atendimento online, e na modalidade presencial atendimentos em, João Pessoa e região.</p>

                    <button className="btn btn-dark fw-bold w-100 btn-brasil">Coloque sua empresa no mapa</button>  

                    </div>

                </div>
            </div>        
        </section>
    )
}

export default Brasil;