import React from "react";
import HeaderCSS from './Header.css'
import CardsHeader from "../Cards-Header/CardsHeader";
import BRAFlag from    '../Flags/BRAFlag'
import USAFlag from '../Flags/USAFlag'
import ThiagoPrincipal from '../../assets/img/Thiago-Principal/Thiago-Principal.png'
import Logo2 from '../../assets/img/Logo/Logo2.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

function Header() {

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

    return (
        <section>
            <div className="container-fluid">
                <div className="row d-flex p-5 align-items-start row-header">

                    <div className="navbar" style={{backgroundColor: 'transparent', zIndex: '2'}}>
                        <div className="container-fluid">

                            <div className="navbar-img" data-aos="fade-right">
                                <img className="w-50" src={Logo2} alt="" />
                            </div>

                            <div className="flags" data-aos="fade-left">
                                <BRAFlag/>
                                <USAFlag/>
                            </div>
                        </div>
                    </div>



                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Preto com 50% de opacidade
                            zIndex: 1
                        }}
                    ></div>

                


                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-4 col-xxl-6 col-text" data-aos="fade-right" style={{ position: 'relative', zIndex: 2 }}>

                        
                        <h6 className="title-header text-white">Contabilidade de resultados</h6>

                        <p className="subtitle-header mt-4 text-white subtitle-header">Nossa equipe experiente oferece soluções integradas para superar qualquer desafio empresarial.</p>

                        <button className="btn fw-bold btn-header">Vamos transformar resultados</button>

                    </div>

                </div>

                <CardsHeader/>
                
            </div>
        </section>
    )
}


export default Header;