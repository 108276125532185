import React from "react";
import FormularioCSS from './Formulario.css';
import Person from '../../assets/img/Forms-Img/Person.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

function Formulario() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);


    return (
        <section>
           <div className="container">
           <div className="container-fluid">
                <div className="row d-flex align-items-center row-forms">
                    <div className="col-sm-12 col-forms">
                        <div className="card justify-content-center card-forms shadow-lg">
                            <div className="row d-flex row-gap-1">

                                <div className="col-sm-6 p-5 col-forms" data-aos="zoom-in">
                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">Nome</label>
                                        <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Digite seu nome..." />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">E-mail</label>
                                        <input type="email" className="form-control shadow-sm placeholder-forms" placeholder="Digite seu e-mail..." />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">Telefone</label>
                                        <input type="tel" className="form-control shadow-sm placeholder-forms" placeholder="Ex: (XX) 9 XXXX-XXXX" />
                                    </div>

                                    <div className="row d-flex align-items-center">
                                        <div className="col-sm-6">
                                            <label className="form-label fw-bold title-user-form">Já possui CNPJ?</label>
                                            <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Sim" />
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="form-label fw-bold title-user-form">Migração de contabilidade?</label>
                                            <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Sim" />
                                        </div>
                                    </div>    

                                    <div className="mb-3 mt-3">
                                        <label className="form-label fw-bold title-user-form">Fale um pouco mais... (Opcional)</label>
                                        <textarea className="form-control shadow-sm placeholder-forms" rows="8" placeholder="Ex: (XX) 9 XXXX-XXXX" />
                                    </div>


                                    <button className="btn w-100 mt-5 btn-dark fw-bold fs-5 button-forms">Enviar</button>

                                </div>

                                
                                
                                                           
                            
                                <div className="col-sm-6 p-5 order-xs-1 order-sm-1" data-aos="zoom-in"> 
                                    <div>
                                        <h6 className="fw-bold text-center fs-1 text-black title-forms">Vamos falar um pouco?</h6>

                                        <p className="text-center fs-6 text-secondary subtitle-forms">Queremos ouvir você!</p>

                                        <img className="img-fluid rounded mx-auto d-block" src={Person} alt="" />
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
           </div>
           </div>
        </section>
    )
};


export default Formulario;