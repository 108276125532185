import React from "react";
import ImageDepo from '../../assets/img/Depoimento/ImageDepo.png'

function CardsDepo(props) {
    return (

    <section>    
        <div className="container"> 

        <div className="row g-4 align-items-center main-cards-depo">

                <div className="col-sm-6" data-aos="fade-right">
                    <div className="card justify-content-center card1-depo shadow">
                        <div className="row d-flex row-gap-1">
                            <div className="col-xs-3 col-sm-12 col-xl-3">
                                <div className="img-wrapper text-center">
                                    <img className="img-fluid rounded-circle img1-depo" src={ImageDepo} alt="Image" />

                                    <div className="star-rating">⭐⭐⭐⭐⭐</div>
                                </div>
                            </div>

                            <div className="col-xs-9 col-sm-12 col-xl-8">
                                <div className="card-body">
                                    <h5 className="card-title title-cardi-depo fw-bold">{props.name1}</h5>
                                        <p className="subtitle-depo fw-bold"><small className="text-muted">{props.subtitle1}</small></p>
                                            <p className="card-text fs-6">{props.text}</p>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6" data-aos="fade-left">
                    <div className="card justify-content-center card2-depo shadow">
                        <div className="row d-flex row-gap-1">
                            <div className="col-xs-3 col-sm-12 col-xl-3">
                                <div className="img-wrapper text-center">
                                    <img className="img-fluid rounded-circle img2-depo" src={ImageDepo} alt="Image" />

                                    <div className="star-rating">⭐⭐⭐⭐⭐</div>
                                </div>
                            </div>

                            <div className="col-xs-9 col-sm-12 col-xl-8">
                                <div className="card-body">
                                    <h5 className="card-title title-cardi-depo fw-bold">{props.name1}</h5>
                                        <p className="subtitle-depo fw-bold"><small className="text-muted">{props.subtitle1}</small></p>
                                            <p className="card-text fs-6">{props.text}</p>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6" data-aos="fade-right">
                    <div className="card justify-content-center card3-depo shadow">
                        <div className="row d-flex row-gap-1">
                            <div className="col-xs-3 col-sm-12 col-xl-3">
                                <div className="img-wrapper text-center">
                                    <img className="img-fluid rounded-circle img3-depo" src={ImageDepo} alt="Image" />

                                    <div className="star-rating">⭐⭐⭐⭐⭐</div>
                                </div>
                            </div>

                            <div className="col-xs-9 col-sm-12 col-xl-8">
                                <div className="card-body">
                                    <h5 className="card-title title-cardi-depo fw-bold">{props.name1}</h5>
                                        <p className="subtitle-depo fw-bold"><small className="text-muted">{props.subtitle1}</small></p>
                                            <p className="card-text fs-6">{props.text}</p>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6" data-aos="fade-left">
                    <div className="card justify-content-center card4-depo shadow">
                        <div className="row d-flex row-gap-1">
                            <div className="col-xs-3 col-sm-12 col-xl-3">
                                <div className="img-wrapper text-center">
                                    <img className="img-fluid rounded-circle img4-depo" src={ImageDepo} alt="Image" />

                                    <div className="star-rating">⭐⭐⭐⭐⭐</div>
                                </div>
                            </div>

                            <div className="col-xs-9 col-sm-12 col-xl-8">
                                <div className="card-body">
                                    <h5 className="card-title title-cardi-depo fw-bold">{props.name1}</h5>
                                        <p className="subtitle-depo fw-bold"><small className="text-muted">{props.subtitle1}</small></p>
                                            <p className="card-text fs-6">{props.text}</p>
                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>


            

           
    </div>
    </section>
        
    )
}

export default CardsDepo;