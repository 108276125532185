import React from "react";
import { useTranslation } from 'react-i18next';
import BandeiraBRA from "../../assets/bandeiras/brasil.png";

const BRAFlag = () => {
    const { i18n } = useTranslation();
    
    return (
        <button className="buttonBRA" style={{backgroundColor: 'transparent', outline: 'none', boxShadow: 'none', overflow: 'hidden', objectFit: 'cover', border: 'none'}} onClick={() => i18n.changeLanguage('pt')}>
            <img
                loading="lazy"
                src={BandeiraBRA}
                className="rounded-lg aspect-[1.56] w-[33px]"
                style={{borderRadius: '50%'}}
            />
        </button>
    );
}

export default BRAFlag;