import React from "react";
import LineCards from '../../assets/img/Line/Line-Cards/Line-Cards.png'
import Globo from '../../assets/img/Diferenciais-icons/Globo.png'
import User from '../../assets/img/Diferenciais-icons/User.png'
import Locker from '../../assets/img/Diferenciais-icons/Locker.png'
import Group from '../../assets/img/Diferenciais-icons/Group.png'
import Home from '../../assets/img/Diferenciais-icons/Home.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import DiferenciaisCSS from    '../Diferenciais/Diferenciais.css'
import Brasil2 from '../../assets/img/Brasil/Brasil2.png'
import { duration } from "@mui/material";


function CardsDiferenciais() {


    useEffect(() => {
        AOS.init({duration: 2000});
      }, []);
      

    return (<section>
        <div className="container" data-aos="zoom-in">
                       <div className="row d-flex row-gap-2 justify-content-start cards-row-dif">
                           <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                               <div className="card card1-dife">
                                   <div className="card-body">

                                           <img src={Globo} alt="" />

                                           <h5 className="card-title mt-4 fw-bold title1-card">Abertura de CNPJ</h5>

                                           <p className="card-text mb-4">Acompanhamento e planejamento de abertura de CNPJ.</p>

                                           <img className="mb-2 img-fluid line1" src={LineCards} alt="" />

                                           <button className="btn-card1 w-100 mt-1 fw-bold">Conhecer</button>

                                       </div>
                                   </div>
                           </div> 

                            <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                <div className="card card2-dife">
                                        <div className="card-body">

                                            <img src={User} alt="" />

                                            <h5 className="card-title mt-4 fw-bold title2-card">Assesoria contábil</h5>

                                            <p className="card-text mb-4">Acompanhamento financeiro e planejemanto contábil.</p>

                                            <img className="mb-2 img-fluid line2" src={LineCards} alt="" />

                                            <button className="btn-card2 w-100 mt-1 fw-bold">Conhecer</button>   

                                        </div>
                                    </div>
                            </div>
                           


                           <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                               <div className="card card3-dife">
                                       <div className="card-body">

                                           <img className="locker" src={Locker} alt="" />

                                           <h5 className="card-title mt-4 fw-bold title3-card">Demonstrativos financeiros</h5>

                                           <p className="card-text mb-4">Emissão e acompanhamentos dos principais demonstrativos.</p>

                                           <img className="mb-2 img-fluid line3" src={LineCards} alt="" />

                                           <button className="w-100 fw-bold btn-card3 ">Conhecer</button>  

                                       </div>
                                   </div>
                           </div> 



                           <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                               <div className="card card4-dife">
                                       <div className="card-body">
                                           
                                           <img className="family" src={Home} alt="" />

                                           <h5 className="card-title mt-4 fw-bold title4-card">Planejamento Sucessório</h5>

                                           <p className="card-text mb-4">Herança organizada e segurança familiar garantida.</p>

                                           <img className="mb-3 img-fluid line4" src={LineCards} alt="" />

                                           <button className="btn-card4 w-100 fw-bold">Conhecer</button>   

                                       </div>
                                   </div>
                           </div> 


                           <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                               <div className="card w-100 card5-dife">
                                       <div className="card-body">

                                           <img className="group" src={Group} alt="" />

                                           <h5 className="card-title mt-4 fw-bold title5-card">Inventário familiar</h5>

                                           <p className="card-text mb-4 subtitle-5">Herança clara, máxima segurança e  divisão sem conflitos.</p>

                                           <img className="mb-3 img-fluid line5" src={LineCards} alt="" />

                                           <button className="btn-card5 w-100 fw-bold">Conhecer</button>   

                                       </div>
                                   </div>
                           </div>

                       </div>
                   </div>

   </section>)
}


export default CardsDiferenciais;