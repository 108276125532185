import React from "react";
import AtendimentosCSS from './Depoimentos.css';
import ImageDepo from '../../assets/img/Depoimento/ImageDepo.png'
import Brasil from '../../assets/img/Brasil/brazil 1.png';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import CardsDepo from "../Cards-Depo/CardsDepo";

function Depoimentos() {

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);


    return (
        <section>
            <div className="container-fluid">
                <div className="row align-items-start main-depo">
                    <div className="col-12 d-flex flex-column mt-5 text-center justify-content-center" data-aos="zoom-in">
                        <h5 className="display-5 fw-bold title-depo">Confiam em nós</h5>
                        <h6 className="fs-4 fw-bold subtitle-depo-title">Veja um pouco de quem faz história conosco</h6>
                    </div>

                    <CardsDepo name1="Daniel Bandeira" subtitle1="CEO Flag Lab" text="Ter o Thiago Nascimento como caminho para a recuperação da minha empresa foi o maior diferencial para sobreviver e crescer!"/>
                   
                
                </div>

                
            </div>
        </section>
    )
}

export default Depoimentos;