import React from "react";
import SolutionsCSS from './Solutions.css';
import Calendar from '../../assets/img/Solutions-Icon/Calendar.png';
import Global from '../../assets/img/Solutions-Icon/Global.png';
import Locker from '../../assets/img/Solutions-Icon/Locker.png';
import User from '../../assets/img/Solutions-Icon/User.png';
import { useTranslation } from "react-i18next";


function Solutions() {

    const { t } = useTranslation();

    return (
        <section>
            <div className="container-solutions">
                <p className="sol-title">{t('Solutions.title1')}</p>

        <div className="container-copy-list">
            <div className="container-copy">
                <div className="copy1">
                    <img className="img-copy1" src={Global} alt="" />
                    <p className="title-copy1">{t('Solutions.title2')}</p>
                    <p className="subtitle-copy1">{t('Solutions.subtitle1')}</p>
            </div>

            <div className="copy2">
                    <img className="img-copy2" src={Locker} alt="" />
                    <p className="title-copy2">{t('Solutions.title3')}</p>
                    <p className="subtitle-copy2">{t('Solutions.subtitle2')}</p>
                </div>
            </div>


            <div className="container-list">
                <div className="list1">
                    <img className="img-list1" src={User} alt="" />
                    <p className="title-list1">{t('Solutions.title4')}</p>
                    <p className="subtitle-list1">{t('Solutions.subtitle3')} </p>
            </div>

                <div className="list2">
                    <img className="img-list2" src={Calendar} alt="" />
                    <p className="title-list2">{t('Solutions.title4')}</p>
                    <p className="subtitle-list2">{t('Solutions.subtitle3')}</p>
                </div>
            </div>
        </div>

            </div>
        </section>
    )
};

export default Solutions;