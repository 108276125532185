import React from "react";
import { useTranslation } from "react-i18next";
import AboutCSS from './About.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

function About() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

    return (
        <section>
            <div className="container-fluid">
                <div className="container-about">
                <div className="row align-items-center justify-content-center main-about">

                <div className="col-sm-4 text-center mt-5 cont-about1" data-aos="zoom-in">
                    <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <span className="fw-bold title1-about">+1 milhão</span>
                            <p className="subtitle1-about">em notas fiscais geradas mensalmente.</p>
                    </div>
                </div>


                <div className="col-sm-4 text-center cont-about2">
                    <div className="d-flex flex-column align-items-center justify-content-evenly" data-aos="zoom-in">
                        <span className="fw-bold title2-about">+1000</span>
                            <p className="subtitle2-about">empresas assistidas</p>
                    </div>
                </div>

                <div className="col-sm-4 text-center align-items-center cont-about3" data-aos="zoom-in">
                    <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <span className="fw-bold title3-about">+500</span>
                            <p className="subtitle3-about">CNPJs gerados</p>
                    </div>
                </div>

                <div className="top"></div>


                </div>
                </div>
            </div>
        </section>
    )
}

export default About;