import './App.css';

import i18n from './assets/js/i18n';
import Header from './components/Header/Header';
import About from './components/About/About';
import Depoimentos from './components/Depoimentos/Depoimentos';
import Brasil from './components/Brasil/Brasil'
import Thiago from './components/Thiago/Thiago';
import Footer from './components/Footer/Footer';
import Formulario from './components/Formulário/Formulario';
import Solutions from './components/Solutions/Solutions';
import Diferenciais from './components/Diferenciais/Diferenciais';
import CardsDiferenciais from './components/Cards-Diferenciais/Cards-Diferenciais';

const App = () => {

  return (
    
    <>
    <Header/>
    <About/>
    <Diferenciais/>
    <Brasil/>
    <Thiago/>
    <Depoimentos/>
    <Formulario/>
    <Footer/>
    </>
  );
}

export default App;
